import React from 'react'
import { Checkbox } from 'antd'

type CommonType = {
    children: React.ReactNode
    selection?: boolean
    isSelected?: (item: any) => boolean
    [x: string]: any
}

export const TableHeaderCell = ({ children, ...props }: CommonType) => {
    return <th {...props}>{children}</th>
}

export const TableCell = ({ children, ...props }: CommonType) => {
    return <td {...props}>{children}</td>
}

export const TableRow = ({ children }) => {
    return <tr className="ant-table-row ant-table-row-level-0">{children}</tr>
}

export const TableBodyRows = ({
    rows,
    children,
    selection,
    keyExtractor,
    onRowToggle,
    isSelected
}) => {
    return rows.map((row, index) => (
        <TableRow key={keyExtractor(row)}>
            {selection && <TableSelectionCell selected={isSelected(row)} onChange={() => onRowToggle(row)} />}
            {children(row, index)}
        </TableRow>
    ))
}

const TableSelectionHeaderCell = ({ onChange, allRowsSelected }) => {
    return (
        <TableHeaderCell className="ant-table-selection-column">
            <Checkbox checked={allRowsSelected} onChange={onChange} />
        </TableHeaderCell>
    )
}

const TableSelectionCell = ({ onChange, selected }) => {
    return (
        <TableCell className="ant-table-selection-column">
            <Checkbox checked={selected} onChange={onChange} />
        </TableCell>
    )
}

export const TableHeaderRow = ({ children, selection, onSelectAll, allRowsSelected, ...props }: CommonType) => {
    return (
        <tr className="ant-table-row ant-table-row-level-0" {...props}>
            {selection && <TableSelectionHeaderCell onChange={onSelectAll} allRowsSelected={allRowsSelected} />}
            {children}
        </tr>
    )
}

export const TableHeader = ({ children }: CommonType) => {
    return <thead className="ant-table-thead">{children}</thead>
}

export const TableFooter = ({ children }: CommonType) => {
    return <tfoot>{children}</tfoot>
}

export const TableBody = ({ rows, children, selection, keyExtractor, onRowToggle, isRowSelected }) => {
    return (
        <tbody className="ant-table-tbody">
            <TableBodyRows
                rows={rows}
                selection={selection}
                isSelected={isRowSelected}
                onRowToggle={onRowToggle}
                keyExtractor={keyExtractor}
            >
                {children}
            </TableBodyRows>
        </tbody>
    )
}

export const Table = ({ children, ...props }: CommonType) => {
    return (
        <div className="ant-table">
            <table {...props}>{children}</table>
        </div>
    )
}
