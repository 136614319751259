import { FieldProps } from 'formik'
import React from 'react'
import { LookupItemSelectField } from './LookupItemSelectField'
import { useTags } from '../../hooks/useTags'
import { Tag } from '../../types'
import useUpdateTag from '../../hooks/useUpdateTag'

type TagSelectProps = {
    defaultValue: string
} & FieldProps

const TagSelect = (props: TagSelectProps) => {
    const { list, map } = useTags()
    const [updateTag] = useUpdateTag()

    return <LookupItemSelectField<Tag> {...props} map={map} list={list} update={updateTag} onChange={undefined} />
}

export default TagSelect
