import React from 'react'
import { TemplateSidebar } from '../components/TemplateSidebar'
import { UploadTransactions } from '../components/UploadTransactions'
import { UploadTransactionsContainer } from '../containers/UploadTransactions'

export const UploadTransactionsPage = props => (
    <TemplateSidebar>
        <UploadTransactionsContainer {...props} Layout={UploadTransactions} />
    </TemplateSidebar>
)

export default UploadTransactionsPage
