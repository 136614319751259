import { Tensor, InferenceSession } from "onnxruntime-web";

const prepareForPrediction = (input) => {
    return input.replace(/[\W]+/, ' ')
}

export async function predictCategory(input) {
    const cleanInput = prepareForPrediction(input)
    const url = "./cat.onnx";
    const session = await InferenceSession.create(url);

    // creating an array of input Tensors is the easiest way. For other options see the API documentation
    const inputs = {
        input: new Tensor([cleanInput]),
    };

    // run this in an async method:
    const outputMap = await session.run(inputs, ['output_label'])
    const outputTensor = outputMap['output_label'].data[0];

    console.log(outputTensor)

    return "" + outputTensor
}
