import React from 'react'

const PickerPreview = {
    maxWidth: '100%'
}

const Container = {}

export class CsvPicker extends React.Component {
    render() {
        const { preview, onPick, ...props } = this.props
        // const { previewText } = this.state

        const title = this.props.title || 'Pick a File'

        const theme = this.props.theme
        const containerStyle = Object.assign({}, Container, theme.picker)
        const previewStyle = Object.assign(
            {},
            PickerPreview,
            theme.pickerPreview,
            theme.halfHeight,
            preview && preview !== 'hidden' ? {} : 'hidden'
        )

        function handleChange(e) {
            onPick && onPick(e.target.files)
        }

        return (
            <div style={containerStyle}>
                {/* {previewText ? (
                    <div style={previewStyle}>
                        <pre style={theme.pre}>{previewText}</pre>
                    </div>
                ) : null} */}
                {/* <Picker title={title} accept="text/csv" theme={theme} onPick={this.handlePick} /> */}
                <input type="file" onChange={handleChange} {...props} />
            </div>
        )
    }
}
