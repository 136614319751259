import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useFirebaseApp } from 'reactfire'
// import { Auth } from 'aws-amplify'

const props = {
    inverse: true,
    size: 'lg',
    fixedWidth: true,
}

const Icon = styled(FontAwesomeIcon)`
    width: 33% !important;
`
export function SidebarHeader() {
    const firebase = useFirebaseApp()

    return (
        <div
            style={{
                fontSize: '20px',
                padding: 0,
                paddingBottom: '5px',
                height: '60px',
                backgroundColor: '#212121',
                paddingTop: '10px',
            }}
        >
            <Link to="/">
                <Icon icon="home" {...props} />
            </Link>
            {/* <Link to={`/accounts`}>
                <Icon icon="money-bill" {...props} />
            </Link> */}
            <Link to="/upload">
                <Icon icon="upload" {...props} />
            </Link>
            {/* <Link to={`/`}>
                <Icon icon="chart-pie" {...props} />
            </Link> */}
            <Link to="/" onClick={() => firebase.auth().signOut()}>
                <Icon icon="power-off" {...props} />
            </Link>
        </div>
    )
}

export default SidebarHeader
