import { useFirestore, useUser } from 'reactfire'
import { User } from 'firebase'

export function useUpdateUserSpecificCollectionItem(collectionName) {
    const firestore = useFirestore()
    const user = useUser<User>()

    function updateItem(options) {
        const collection = firestore.collection("users").doc(user.uid).collection(collectionName)
        const doc = collection.doc()

        doc.set({
            name: options,
        })
    }

    return [updateItem]
}

export default useUpdateUserSpecificCollectionItem
