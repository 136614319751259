import { FieldProps, useFormikContext } from 'formik'
import React from 'react'
import { LookupItemSelectField } from './LookupItemSelectField'
import { Account } from '../../types'
import { useCategories } from '../../hooks/useCategories'
import { useAccounts } from '../../hooks/useAccounts'
import useUpdateCategory from '../../hooks/useUpdateCategory'

type CategorySelectProps = {
    accounts: Account[]
    accountsMap: { [x: string]: Account }
    defaultValue?: string
} & FieldProps

const extendCategoryList = (categories, accounts) => {
    const accountOptions = accounts.map(item => ({
        ...item,
        name: `[${item.name}]`,
    }))

    return [...categories, ...accountOptions]
}

type CategorySelectOption = { uid: string; name?: string | null; __typename?: 'Account' | 'Category' }

// TODO: add extra options for accounts
const CategorySelect = ({ field, form, meta, ...props }: CategorySelectProps) => {
    const { list: categories, map: categoriesMap } = useCategories()
    const { list: accounts, map: accountsMap } = useAccounts()
    const [updateCategory] = useUpdateCategory()
    const { setFieldValue } = useFormikContext<any>()

    const options = extendCategoryList(categories, accounts)
    const optionsMap: { [x: string]: CategorySelectOption } = { ...categoriesMap, ...accountsMap }

    const handleChange = (name, value) => {
        if (accountsMap[value]) {
            setFieldValue('toAccount', value)
            setFieldValue(name, null)

            return
        }
        setFieldValue(name, value)
        setFieldValue('toAccount', null)
    }

    return (
        <LookupItemSelectField<CategorySelectOption>
            {...props}
            map={optionsMap}
            list={options}
            update={updateCategory}
            field={field}
            form={form}
            meta={meta}
            onChange={handleChange}
        />
    )
}

export default CategorySelect
