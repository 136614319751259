import { FieldProps } from 'formik'
import React from 'react'
import { LookupItemSelectField } from './LookupItemSelectField'
import { usePayees } from '../../hooks/usePayees'
import useUpdatePayee from '../../hooks/useUpdatePayee'
import { Payee } from '../../types'

type PayeeSelectProps = {
    defaultValue: string
} & FieldProps

const PayeeSelect = (props: PayeeSelectProps) => {
    const { map: payeesMap, list: payees } = usePayees()
    const [updatePayee] = useUpdatePayee()
    
    return (
        <LookupItemSelectField<Payee>
            {...props}
            map={payeesMap}
            list={payees}
            update={updatePayee}
            onChange={undefined}
        />
    )
}

export default PayeeSelect
