import React from 'react'
import { TemplateSidebar } from '../components/TemplateSidebar'
import { UpdateTransaction } from '../components/UpdateTransaction'
import { UpdateTransactionContainer } from '../containers/UpdateTransaction'

export type UrlMatch = {
    params: {
        accountId: string
    }
}

export type EditAccountProps = {
    match: UrlMatch
}

export type EditAccountPageType<T> = {
    Layout: React.ComponentType<T>
    accountId: string
}

export const UpdateTransactionPage = (props: EditAccountProps) => (
    <TemplateSidebar>
        <UpdateTransactionContainer
            Layout={UpdateTransaction}
            {...props}
        />
    </TemplateSidebar>
)

export default UpdateTransactionPage
