import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Dropdown } from 'antd'
import { formatTransactionDate } from '../lib/utils'
import { InlineEditorToggleCallback } from '../hooks/useInlineEditorState.interface'
import { TableCell } from './table'
import { Transaction, Category, Payee, Tag, Account } from '../types'
import { predictCategory } from '../lib/ml'
import { getCategory } from '../lib/db/categories'

const getName = (id?: string | number | null, item?) => {
    return item && item.name ? item.name : id
}

const TransactionColumn = styled(TableCell)`
  justify-content: left
  width: 16%
`

type TransactionItemProps = {
    transaction: Transaction
    deleteTransaction: (accountId: string, transactionId: string) => void
    onInlineEditorToggle: InlineEditorToggleCallback
    account: Account
    toAccount?: Account
    category: Category
    payee: Payee
    tag: Tag
    balance?: number
    accountId: string,
    accountCurrency: string,
}

const renderMenu = ({ deleteTransaction, transaction, onInlineEditorToggle, category }) => (
    <Menu>
        {/* <Menu.Item key="0">
            <span
                onClick={e => {
                    e.preventDefault()
                    onInlineEditorToggle && onInlineEditorToggle(transaction)
                }}
            >
                <FontAwesomeIcon icon="edit" />
            </span>
        </Menu.Item> */}
        <Menu.Item key="1">
            <span
                onClick={e => {
                    e.preventDefault()
                    window.confirm('Delete this transaction?') && deleteTransaction(transaction.accountId, transaction.uid)
                }}
            >
                <FontAwesomeIcon icon="trash" />
            </span>
        </Menu.Item>
        <Menu.Item key="2">
            <span
                onClick={e => {
                    e.preventDefault()
                    predictCategory(transaction.notes).then(prediction => getCategory(prediction).then(category => alert(JSON.stringify(category.name))))
                }}
            >
                <FontAwesomeIcon icon="question" />
            </span>
        </Menu.Item>
    </Menu>
)

// TODO: come up with better naming scheme
export const TransactionItem = ({
    transaction,
    deleteTransaction,
    onInlineEditorToggle,

    tag,
    account,
    toAccount,
    category,
    payee,

    accountId,
    accountCurrency,

    balance,
}: TransactionItemProps) => (
    <>
        <TransactionColumn className="transaction-date">
            {transaction.charged ? formatTransactionDate(transaction.charged) : 'ERROR: no date provided'}
        </TransactionColumn>
        <TransactionColumn className="transaction-payee" title={getName(transaction.payee, payee)}>
            {getName(transaction.payee, payee)}
        </TransactionColumn>
        {transaction.toAccount ? (
            <TransactionColumn className="transaction-category" title={getName(transaction.toAccount, account)}>
                {`[${getName(transaction.toAccount, toAccount)}]`}
            </TransactionColumn>
        ) : (
            <TransactionColumn className="transaction-category" title={getName(transaction.category, category)}>
                {getName(transaction.category, category)}
            </TransactionColumn>
        )}
        <TransactionColumn className="transaction-tag" title={getName(transaction.tag, tag)}>
            {getName(transaction.tag, tag)}
        </TransactionColumn>
        <TransactionColumn className="transaction-notes" title={transaction.notes}>
            {transaction.notes}
        </TransactionColumn>
        <TransactionColumn className="transaction-amount">
            {transaction.amount && transaction.amount.toFixed(2)}
        </TransactionColumn>
        <TransactionColumn className="transaction-balance" style={{ color: balance && balance < 0 ? 'red' : '' }}>
            {balance &&
                balance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    currency: accountCurrency || 'EUR',
                    style: 'currency',
                })}
        </TransactionColumn>
        <TransactionColumn className="transaction-controls">
            <span
                onClick={e => {
                    e.preventDefault()
                    onInlineEditorToggle && onInlineEditorToggle(transaction)
                }}
            >
                <FontAwesomeIcon icon="edit" />
            </span>
            <Dropdown
                overlay={renderMenu({ deleteTransaction, onInlineEditorToggle, transaction, category })}
                trigger={['click']}
            >
                <FontAwesomeIcon icon="ellipsis-v" />
            </Dropdown>
        </TransactionColumn>
    </>
)

export default TransactionItem
