import React, { Suspense } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Layout, PageHeader, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { TransactionContainer } from '../containers/Transaction'
import { RowSelectedToggleHandler } from '../hooks/useRowsSelector.interface'
import { TransactionsContainerCombinedProps } from '../containers/Transactions'
// import { UpdateTransactionContainer } from '../../containers/UpdateTransaction'
import { UpdateTransaction as UpdateTransactionComponent } from './UpdateTransaction'
import { TransactionBulkUpdateControls } from './TransactionBulkUpdateControls'
import { TransactionItem } from './TransactionItem'
import {
    Account,
    Transaction,
} from '../types'

import { Table, TableHeader, TableHeaderCell, TableBody, TableHeaderRow, TableCell } from './table'
import useDeleteTransaction from '../hooks/useDeleteTransaction'
import UpdateTransactionContainer from '../containers/UpdateTransaction'
import { InlineEditorToggleCallback } from '../hooks/useInlineEditorState.interface'
import { useRowsSelector } from '../hooks/useRowsSelector'
import TransactionBulkUpdateControlsContainer from '../containers/TransactionBulkUpdateControls'

// export type RefetchFn<T> = {
//     (variables?: OperationVariables): Promise<T>
// }

export type TransactionsListingComponentProps = {
    accountId: string
    onSelectAll: (state: boolean) => void
} & TransactionsContainerCombinedProps &
    TransactionsListingType

export interface TransactionsListingType {
    moveCursor: () => void
    hasMore: boolean
    onRowSelectedToggle: RowSelectedToggleHandler
    transactions: Transaction[]
    // refetch: RefetchFn<Transaction>
    inlineEditorItem: Transaction
    // accounts: Account[]
    onInlineEditorToggle: InlineEditorToggleCallback
    selectedRows: Transaction[]
}

export const Transactions = ({
    hasMore,
    transactions,
    moveCursor,
    refetch,
    account,
    moveTransactions,
    history,
    onInlineEditorToggle,
    inlineEditorItem,
}) => {
    const {
        selectedRows,
        allRowsSelected,
        onRowSelectedToggle,
        onSelectAllRowsToggle,
        isRowSelected,
        deselectRows,
        deselectAllRows,
        setSelectedRows,
    } = useRowsSelector<Transaction>()

    const renderInlineEditor = (item: Transaction) => {
        return (
            <td colSpan={8}>
                <UpdateTransactionContainer
                    key={item.charged}
                    Layout={UpdateTransactionComponent}
                    onEditorToggle={onInlineEditorToggle}
                    transaction={item}
                    closeEnabled
                />
            </td>
        )
    }

    const [deleteTransaction] = useDeleteTransaction()

    const renderItem = (item: Transaction, balance: number) => {
        const currency = account && account.currency

        return (
            <Suspense fallback={<TableCell>Loading...</TableCell>}>
                <TransactionContainer
                    key={item.uid}
                    transaction={item}
                    Layout={TransactionItem}
                    onInlineEditorToggle={onInlineEditorToggle}
                    accountId={account.uid}
                    balance={balance}
                    accountCurrency={currency || undefined}
                    deleteTransaction={deleteTransaction}
                />
            </Suspense>
        )
    }

    const renderTitle = () => {
        return [
            account && (account.name || account.uid),
            <Divider key="title-2" type="vertical" />,
            <Link key="title-2-link" to={`/accounts/${account && account.uid}`}>
                <FontAwesomeIcon icon="edit" />
            </Link>,
        ]
    }

    const renderHeader = () => {
        const controls = (
            <TransactionBulkUpdateControlsContainer
                Layout={TransactionBulkUpdateControls}
                moveTransactions={moveTransactions}
                accountId={account.uid}
                selectedRows={selectedRows}
                style={{ float: 'right' }}
                onRowSelectedToggle={onRowSelectedToggle}
                deselectRows={deselectRows}
                onSelectAllRowsToggle={onSelectAllRowsToggle}
                deselectAllRows={deselectAllRows}
                setSelectedRows={setSelectedRows}
            />
        )

        return <PageHeader onBack={() => history.push('/')} title={renderTitle()} extra={controls} />
    }

    const renderNewItemForm = () => (
        <div className="edit-transaction-form-container">
            <UpdateTransactionContainer
                // @ts-ignore
                Layout={UpdateTransactionComponent}
                closeEnabled={false}
            />
        </div>
    )

    if (!Array.isArray(transactions)) {
        return <Spin spinning delay={100} size="large" tip="Loading Transactions..." />
    }

    const renderRow = (item: Transaction, balance: number) => {
        const editing =
            inlineEditorItem && inlineEditorItem.accountId === item.accountId && inlineEditorItem.uid === item.uid

        return editing ? renderInlineEditor(item) : renderItem(item, balance)
    }

    const renderList = () => {
        let balance = account && account.balance ? account.balance : 0

        return (
            <InfiniteScroll
                dataLength={transactions.length} // This is important field to render the next data
                next={moveCursor}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage="Yay! You have seen it all"
                scrollableTarget="main_content"
                // below props only if you need pull down functionality
                // refreshFunction={refetch}
                // pullDownToRefresh
                // pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
                // releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
                style={{ minHeight: '300px' }}
            >
                <div style={{ background: 'rgb(255, 255, 255)' }}>
                    <Table className="transactions-container">
                        <TableHeader>
                            <TableHeaderRow
                                selection
                                style={{ backgroundColor: 'transparent' }}
                                onSelectAll={() => onSelectAllRowsToggle(transactions)}
                                allRowsSelected={allRowsSelected}
                            >
                                <TableHeaderCell colSpan={9} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {renderNewItemForm()}
                                </TableHeaderCell>
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody
                            selection
                            rows={transactions}
                            keyExtractor={item => item.uid}
                            onRowToggle={onRowSelectedToggle}
                            isRowSelected={isRowSelected}
                        >
                            {(item, index) => {
                                const prevItem = index > 0 ? transactions[index - 1] : { balance, amount: 0 }
                                item.balance = prevItem.balance - prevItem.amount

                                return renderRow(item, item.balance)
                            }}
                        </TableBody>
                    </Table>
                </div>
            </InfiniteScroll>
        )
    }

    // Show Listing
    return (
        <Layout id="scrollTarget">
            {renderHeader()}
            <Layout.Content>{renderList()}</Layout.Content>
        </Layout>
    )
}

export default Transactions
