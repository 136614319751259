import React from 'react'
import { useAccounts } from '../hooks/useAccounts'
import { AccountsContainerProps, GroupedAccounts } from './Accounts.interface'
import { Account } from '../types'

const exchangeRates = {
    EUR: 0.84,
    UAH: 27.68,
    LVL: 0.58,
    USD: 1,
}

const convertToMain = (amount: number, currency: string) => {
    return amount / exchangeRates[currency]
}

const convertToGroupMap = (sortedAccounts: Account[]): GroupedAccounts => {
    const groupedAccounts = {}
    sortedAccounts.forEach(account => {
        const group = account.group || 'Ungrouped'
        if (!groupedAccounts[group]) {
            groupedAccounts[group] = { accounts: [], total: 0 }
        }
        groupedAccounts[group].accounts.push(account)
        groupedAccounts[group].total += convertToMain(account.balance || 0, account.currency || 'EUR')
    })
    return groupedAccounts
}

export const AccountsContainer = ({ Layout, ...props }: AccountsContainerProps) => {
    const accountsListProps = useAccounts()
    const { list } = accountsListProps
    const accounts = [...list].sort((a: Account, b: Account) => (a.name || '').localeCompare(b.name || ''))
    const groupedAccounts = convertToGroupMap(accounts)

    return <Layout accounts={accountsListProps} groupedAccounts={groupedAccounts} {...props} />
}

export default AccountsContainer
