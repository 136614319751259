import { addMonths, format, differenceInMonths } from 'date-fns'
import React, { useState, ElementType, useEffect } from 'react'
import useAccounts from '../hooks/useAccounts'
import useCategories from '../hooks/useCategories'
import useChartData, { ChartData, XyChartPoint } from '../hooks/useChartData'

type ChartsContainerProps = {
    Layout: ElementType<any>
}

const accounts = [
    // mine
    'd89919b7-c3d5-4b97-8d51-93c2df3d1bee',
    'b5cdc1a3-9b96-482c-9a2b-fa01be9a82fb',
    '6aec5bd9-e832-453d-accd-b36d53477603',
    '22a8ec31-48d6-4a12-85c5-34a70bac43cb',
    'b8e85f01-7980-4163-8e69-55374ecbda70',

    // olgas
    '1ae68102-e263-4c02-902c-4071bb505650',
    '6b338635-7dc5-496c-822a-8f91e731a72b',
    'ba082dba-ed69-48fc-9fa6-84786f88d2a4',
    'e1520b6e-3b67-4c2c-a958-414940bc5c3c',
]

export const ChartsContainer = ({ Layout, ...props }: ChartsContainerProps) => {
    const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1))
    const [dateTo, setDateTo] = useState(addMonths(new Date(), 0))
    const [groupBy, setGroupBy] = useState('month')
    const {map} = useCategories()
    const {list: accounts} = useAccounts()
    const [focusedInput, setFocusedInput] = useState(null)

    const defaultAccounts = accounts.filter(account => account.isExcluded !== true).map(account => account.uid)
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>(defaultAccounts)

    const handleDateFromChange = (date: Date) => {
        setDateFrom(date)
    }

    const handleDateToChange = (date: Date) => {
        setDateTo(date)
    }

    const handleGroupByChange = (groupBy: string) => {
        setGroupBy(groupBy)
    }

    const handleAccountsChange = (accounts: string[]) => {
        console.log('Accounts selected:', accounts)
        setSelectedAccounts(accounts)
    }

    const renderChildren = (chartData: ChartData) => {
        const diff = differenceInMonths(dateTo, dateFrom)
        const categoriesRangeArray: Date[] = []
        for (let i = 0; i <= diff; i += 1) {
            categoriesRangeArray.push(addMonths(dateFrom, i))
        }
        const categories = categoriesRangeArray.map(m => format(m, 'yyyy-MM'))

        const calcAverage = (items: XyChartPoint[]) => {
            const itemsTotal = items.reduce(
                (a: number, value: XyChartPoint) => a + (value && value.y ? parseFloat(value.y) : 0),
                0
            )

            return Math.floor((100 * itemsTotal) / items.length) / 100
        }
        const cashflowAverages = {
            expense: chartData && calcAverage(chartData.expense),
            income: chartData && calcAverage(chartData.income),
        }

        return (
            <Layout
                chartData={chartData}
                onDateFromChange={handleDateFromChange}
                onDateToChange={handleDateToChange}
                onAccountsChange={handleAccountsChange}
                onGroupByChange={handleGroupByChange}
                accounts={accounts}
                selectedAccounts={selectedAccounts}
                dateFrom={dateFrom}
                dateTo={dateTo}
                groupBy={groupBy}
                categories={categories}
                categoriesMap={map}
                cashflowAverages={cashflowAverages}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                {...props}
            />
        )
    }

    const chartData = useChartData(dateFrom, dateTo, groupBy, selectedAccounts)

    return renderChildren(chartData)
}

export default ChartsContainer
