import React from 'react'
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firebase from 'firebase';
import { useFirebaseApp } from 'reactfire'

const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ]
};

export const SignIn = () => {
    const firebase = useFirebaseApp()
    return (
        <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    )
}

export default SignIn
