import { User } from 'firebase'
import { useEffect, useState } from 'react'
import { useFirestore, useUser } from 'reactfire'
import { Transaction } from '../types'


export function useLinkSuggestions({ updateValues }) {
    const firestore = useFirestore()
    const [linkSuggestions, setLinkSuggestions] = useState<Transaction[]>([])
    const user = useUser<User>()

    useEffect(() => {
        console.log(updateValues)

        if (!updateValues || !updateValues.amount || !updateValues.toAccount || !updateValues.charged) {
            setLinkSuggestions([])
            return
        }

        const collection = firestore
            .collection('users')
            .doc(user.uid)
            .collection('accounts')
            .doc(updateValues.toAccount)
            .collection('transactions')
            .where('charged', '>=', updateValues.charged - 100000000)
            .where('charged', '<=', updateValues.charged + 100000000)
            .where('amount', '==', -updateValues.amount)
            .orderBy('charged', 'desc')
            .limit(10)

        collection.get()
            .then(result => {
                return result.docs.map(doc => ({ uid: doc.id, ...doc.data() })).filter((t: Transaction) => t.toAccount == null)
            })
            .then((transactions: Transaction[]) => {
                setLinkSuggestions(transactions)
            })

    }, [updateValues])

    return { transactions: linkSuggestions }
}

export default useLinkSuggestions
