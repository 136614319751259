import { useFunctions } from "reactfire"

export const useUploadTransactions = (options?) => {
    const functions = useFunctions()

    const uploadTransactions = functions.httpsCallable('uploadTransactions')

    return [uploadTransactions]
}

export default useUploadTransactions
