import { Category } from '../types'
import { convertArrayToIdMap } from '../lib/utils'
import useUserSpecificCollection from './useUserSpecificCollection'

export const useCategories = (): { list: Category[], loading: boolean, error?: string, map: {[x: string]: Category} } => {
    const list = useUserSpecificCollection<Category>('categories')
    const map = Array.isArray(list) ? convertArrayToIdMap(list) : {}

    return { list, map, loading: false, error: null }
}

export default useCategories
