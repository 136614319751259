import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { AccountSelectWithQuery as AccountSelect } from './form/AccountSelect'
import { Transaction } from '../types'
import { WithRowsSelectorType } from '../hooks/useRowsSelector.interface'

const BulkControlsContainer = styled.div`
    text-align: right;
`

const Control = styled.div`
    display: inline-block;
`

const SelectControl = styled(Control)`
    width: 250px;
    text-align: left;
`

const BulkAccountSelect = styled(AccountSelect)`
    width: 250px;
`

const TransactionCounter = styled.span`
    font-size: 10px;
    position: absolute;
    right: 24px;
`

type Props = {
    onMoveTransactions: () => void
    onDeleteTransactions: () => void
    onAccountSelect: { (account: string) }
} & WithRowsSelectorType<Transaction>

export const TransactionBulkUpdateControls = ({
    onMoveTransactions,
    onAccountSelect,
    onDeleteTransactions,
    selectedRows,
}: Props) => (
    <BulkControlsContainer>
        <SelectControl>
            <BulkAccountSelect onChange={(_name, account: string) => onAccountSelect(account)} />
        </SelectControl>
        <Control>
            <Button onClick={onMoveTransactions}>Move</Button>
        </Control>
        <Control>
            <Button
                onClick={e => {
                    e.preventDefault()
                    window.confirm('Do you want to delete selected transactions?') && onDeleteTransactions()
                }}
            >
                Delete
            </Button>
        </Control>
        <br />
        {selectedRows && <TransactionCounter>Rows selected: {selectedRows.length}</TransactionCounter>}
    </BulkControlsContainer>
)

export default TransactionBulkUpdateControls
