import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import HomePage from './Home'
import SignInPage from './SignIn'
import { AccountTransactionsPage } from './AccountTransactions'
import { useUser } from 'reactfire'
import { ErrorPage } from './Error'
import UpdateAccountPage from './UpdateAccount'
import UpdateTransactionPage from './UpdateTransaction'
import { ChartsPage } from './Charts'
import { UploadTransactionsPage } from './UploadTransactions'

function Index() {
    const user = useUser()

    return (
        <Switch>
            <Route
                exact
                path="/sign-in"
                component={SignInPage}
            />
            {!user ? <Redirect to="/sign-in" /> : <>
                <Route
                    exact
                    path="/"
                    component={HomePage}
                />
                <Route
                    exact
                    path="/charts"
                    component={ChartsPage}
                />                
                <Route
                    exact
                    path="/accounts/:accountId/transactions"
                    component={AccountTransactionsPage}
                />
                <Route
                    exact
                    path="/accounts"
                    component={UpdateAccountPage}
                />
                <Route
                    exact
                    path="/accounts/:accountId"
                    component={UpdateAccountPage}
                />
                <Route
                    exact
                    path="/accounts/:accountId/transactions/:transactionId"
                    component={UpdateTransactionPage}
                />                
                
                <Route
                    path="/upload"
                    component={UploadTransactionsPage}
                />
                
            </>}
            <Route component={ErrorPage} />
        </Switch>
    )
}

export default Index
