import { Account } from '../../types'
import { firestore } from 'firebase'

export function getAccountDoc(userId: string, id: string): firestore.DocumentReference<Account> | undefined {
    const accountDoc = firestore()
        .collection('users')
        .doc(userId)
        .collection('accounts')
        .doc(id || '0')

    return accountDoc as firestore.DocumentReference<Account>
}

export async function getAccount(userId: string, id: string): Promise<Account | undefined> {
    const doc = getAccountDoc(userId, id)

    return { uid: doc.id, ...(await doc.get()).data() }
}
