import React, { PureComponent } from 'react'
import CreatableSelect, { Props as CreatableProps } from 'react-select/creatable'
import styled from 'styled-components'
import { ValueType, ActionMeta } from 'react-select/src/types'
import { LookupItem } from '../../types'

const AutocompleteContainer = styled.div`
    flex-grow: 1;
`

const styles = {
    option: provided => ({
        ...provided,
        paddingLeft: '10px !important',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingTop: '10px',
        paddingBottom: '10px',
    }),
    menu: provided => ({
        ...provided,
        overflow: 'hidden',
    }),
    menuList: provided => ({
        ...provided,
        overflow: 'hidden',
    }),
}

export interface AutocompleteProps<T> extends CreatableProps<T, false> {
    onChange?: (newValue: ValueType<T, false> | string, actionMeta: ActionMeta<T> | string) => void
}

export class Autocomplete<T extends LookupItem> extends PureComponent<AutocompleteProps<T>> {
    ref: any

    public constructor(props) {
        super(props)
        this.ref = React.createRef()
    }

    private handleChange = value => {
        const { onChange, name } = this.props
        onChange && onChange(name, value && value.uid)
    }

    private handleCreate = (name: string) => {
        const { onCreate } = this.props
        onCreate(name)
    }

    public render() {
        const { options, value, className, tabIndex, defaultValue, error, touched, ...props } = this.props

        return (
            <AutocompleteContainer>
                <CreatableSelect<T>
                    {...props}
                    styles={styles}
                    ref={this.ref}
                    isClearable
                    options={options}
                    getOptionLabel={option => option.name || ''}
                    getOptionValue={option => option.uid}
                    defaultValue={defaultValue}
                    getNewOptionData={inputValue => ({ uid: inputValue, name: inputValue } as T)}
                    onChange={this.handleChange}
                    onCreateOption={this.handleCreate}
                    backspaceRemovesValue
                    autofocus
                    value={value}
                    className={className}
                    tabIndex={tabIndex}
                    blurInputOnSelect
                />
                {!!error && touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>}
            </AutocompleteContainer>
        )
    }
}

export default Autocomplete
