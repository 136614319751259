import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'
import { User } from 'firebase'

export const useUserSpecificCollection = <T>(collectionName: string): T[] => {
    const user = useUser<User>()
    const collection = useFirestore().collection('users').doc(user.uid).collection(collectionName)
    const collectionData = useFirestoreCollectionData<T>(collection, {idField: 'uid'})

    return collectionData
}

export default useUserSpecificCollection
