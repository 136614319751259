import React, { useEffect, useRef, useState } from 'react'
import { useTransactions } from '../hooks/useTransactions'
import { Account, Transaction } from '../types'
import useInlineEditorState from '../hooks/useInlineEditorState'
import { useFirestore, useUser } from 'reactfire'
import { firestore, User } from 'firebase'

export type TransactionsContainerLayoutProps = {
    transactions: Transaction[]
    account: Account
    inlineEditorItem: any
    onInlineEditorToggle: any
    hasMore: boolean
    moveCursor: () => void
}

export interface TransactionsContainerProps {
    Layout: React.ElementType<TransactionsContainerLayoutProps>
    account: Account
}

export type TransactionsContainerCombinedProps = TransactionsContainerProps

export const TransactionsContainer = ({ Layout, account }: TransactionsContainerCombinedProps) => {
    const [inlineEditorItem, onInlineEditorToggle] = useInlineEditorState()

    const user = useUser<User>()
    const firestore = useFirestore()

    const [cursor, setCursor] = useState<FirebaseFirestore.QueryDocumentSnapshot>()
    // const [transactions, setTransactions] = useState(undefined)
    const [fetching, setFetching] = useState(true)
    const [nextToken, setNextToken] = useState<FirebaseFirestore.QueryDocumentSnapshot>()
    const [pages, setPages] = useState([[]]), listeners = useRef([]);

    const perPage = 50

    useEffect(() => () => {
        setCursor(undefined)
        setFetching(true)
        setPages([[]])
        setNextToken(undefined)
    }, [account]);

    useEffect(() => () => {listeners.current.map(l => l())}, []);

    useEffect(() => {
        if (!fetching) return;
        const l = pages.length

        const collection = firestore
            .collection('users')
            .doc(user.uid)
            .collection('accounts')
            .doc(account.uid)
            .collection('transactions')
            .orderBy('charged', 'desc')
            .limit(perPage)

        const cusorCollection = cursor ? collection.startAfter(cursor) : collection
    

        let mounted = true;
        cusorCollection.get().then(snapshot => {
            if (mounted) {
                const items = snapshot.docs.map(doc => {
                    return {uid: doc.id, ...doc.data() as Transaction}
                });
                
                const last = items.length == perPage ? snapshot.docs[items.length - 1] : undefined
                //@ts-ignore
                setNextToken(last)

                setPages(ps => ps.concat([items]));
                const unsubscribe = cusorCollection.endAt(items[items.length - 1]?.charged || 999999999999999).onSnapshot(snapshot => {
                    const transactionsUpdated = snapshot.docs.map(doc => {
                        return {uid: doc.id, ...doc.data() as Transaction}
                    });
                    setPages(ps => ps.map((b, i) => i === l ? transactionsUpdated : b));
                });
                listeners.current = listeners.current.concat([unsubscribe]);
                setFetching(false);
            }
        });
        return () => {mounted = false};
    }, [fetching]);

    const moveCursor = () => {
        console.log("moveCursor")
        setCursor(nextToken)
        setFetching(true)
    }

    const transactions = pages.flat()

    return (
        <Layout
            hasMore={!!nextToken}
            moveCursor={moveCursor}
            transactions={transactions}
            account={account}
            inlineEditorItem={inlineEditorItem}
            onInlineEditorToggle={onInlineEditorToggle}
        />
    )
}

export default TransactionsContainer
