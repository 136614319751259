import React from 'react'
import { Layout } from 'antd'
import SimpleBar from 'simplebar-react'
import { Sidebar } from './Sidebar'
import { SuspenseWithPerf } from 'reactfire'
import Loading from './Loading'

export const TemplateSidebar = ({ children }) => (
    <Layout className="layout">
        <Sidebar />
        <Layout>
            <SimpleBar scrollableNodeProps={{ id: 'main_content' }} style={{ height: '100vh' }}>
                <SuspenseWithPerf fallback={<Loading/>} traceId="template-sidebar">
                    {children}
                </SuspenseWithPerf>
            </SimpleBar>
        </Layout>
    </Layout>
)

export default TemplateSidebar
