import React, { useState } from 'react'
import styled from 'styled-components'
import { Layout, PageHeader, Spin, Button, Form, message } from 'antd'

import { Autocomplete, AutocompleteProps } from './form/Autocomplete'
import { Uploader } from './form/Uploader'
import { AccountSelectWithQuery, AccountSelectProps } from './form/AccountSelect'
import { useStorage, useUser } from 'reactfire'
import useUploadTransactions from '../hooks/useUploadTransactions'
import { LookupItem } from '../types'
import { User } from 'firebase'

type PickerFile = {
    name: string
    size: number
    type: string
    file: File
}

const sourceOptions = [
    { name: 'swedbank', uid: 'swedbank' },
    { name: 'paypal', uid: 'paypal' },
    { name: 'revolut', uid: 'revolut' },
    { name: 'moneyok', uid: 'moneyok' },
]

const theme = {
    pickerInput: {
        height: '40px',
        backgroundColor: 'lightsalmon',
        position: 'inherit',
        cursor: 'pointer',
        display: 'block',
        maxWidth: '400px',
        marginTop: '-40px',
    },
    photoPickerButton: {
        cursor: 'pointer',
        maxWidth: '400px',
        top: 0,
        left: 0,
    },
    pre: {
        maxHeight: '600px',
        overflowY: 'scroll',
        boxShadow: '1px 4px 11px -5px black',
        marginBottom: '40px',
    },
}

const FileUploader = styled(Uploader)`
    width: 200px;
    height: 200px;
    background-color: lightsalmon;
`

const FileUploaderContainer = styled.div``

type Props = {
    uploadTransactions: ReturnType<typeof useUploadTransactions>[0]
}

export const UploadTransactions = ({ uploadTransactions }: Props) => {
    const [files, setFiles] = useState<PickerFile[]>()
    const [account, setAccount] = useState<string>()
    const [source, setSource] = useState<string>()
    const user = useUser<User>()
    const storage = useStorage()

    const uploadFile = async (files: PickerFile[]): Promise<string> => {
        var storageRef = storage.ref().child('temp.csv');
        console.log(files[0])
        //@ts-ignore
        const snapshot = await storageRef.put(files[0])
        console.log('Uploaded a blob or file!');
        return snapshot.metadata.fullPath
    }

    const handleAccountChange: AccountSelectProps['onChange'] = (_, value) => {
        setAccount(value as string)
    }

    const handleSourceChange: AutocompleteProps<LookupItem>['onChange'] = (_, value) => {
        setSource(value as string)
    }

    const handleUpload = (data: PickerFile[]) => {
        console.log(`Files picked: `, data)
        setFiles(data)
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (!account || !source || !files) {
            alert('Not all fields selected!')
            console.log(account, source, files)

            return
        }

        uploadFile(files)
            .then(async path => {
                const result = await uploadTransactions({ accountId: account, sourceKey: source, globPattern: path, user: user.uid })
                const { data } = result
                if (data && data.importTransactions) {
                    message.success(`Transaction import: ${data.result}`)
                    // TODO: refetch transactons
                    // TODO: show transactions added/updated/ignored
                    return data
                }
                message.error('Transaction import: failed')
                throw Error(`Transaction import failed: + ${result}`)
            })
            .catch(error => console.log(error))
    }

    return (
        <Layout>
            <PageHeader title="Upload Transactions" />
            <Layout.Content>
                <Spin spinning={false} size="large" delay={100}>
                    <Form>
                        <div style={{ maxWidth: '400px' }}>
                            <Form.Item>
                                <AccountSelectWithQuery placehodler="Account" onChange={handleAccountChange} />
                            </Form.Item>
                            <Form.Item>
                                <Autocomplete
                                    placeholder="Source"
                                    //@ts-ignore
                                    options={sourceOptions}
                                    onChange={handleSourceChange}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <h3>{files && files[0].name}</h3>
                        </div>
                        <Form.Item>
                            <FileUploaderContainer>
                                <FileUploader onChange={handleUpload} theme={theme} accept=".txt" />
                            </FileUploaderContainer>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={handleSubmit}>
                                Import
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Layout.Content>
        </Layout>
    )
}

export default UploadTransactions
