import { useEffect, useState } from "react"
import { useFunctions } from "reactfire"
import { format } from "date-fns"

export type XyChartPoint = {
   x: string,
   y: string,
 }

export type ChartData = {
   income: XyChartPoint[],
   expense: XyChartPoint[],
   totalsByCategory: XyChartPoint[],
   netWorth: XyChartPoint[],
 }

export function useChartData(dateFrom: Date, dateTo: Date, groupBy: string, accounts: string[]): ChartData {
    const [chartData, setChartData] = useState<ChartData>()
    const functions = useFunctions()
    
    useEffect(() => {
        const getChartData = functions.httpsCallable('chartData')
        console.log(`Requesting charts data`)
        getChartData({dateFrom: format(dateFrom, 'yyyy-MM-dd'), dateTo: format(dateTo, 'yyyy-MM-dd'), groupBy, accounts: accounts || []}).then((response) => {
            console.log(`Charts Data: ${response}`)
            setChartData(response.data)
        })
    }, [dateFrom, dateTo, groupBy, accounts])

    return {
        income: chartData ? chartData.income : [],
        expense: chartData ? chartData.expense : [],
        totalsByCategory: chartData ? chartData.totalsByCategory : [],
        netWorth: chartData ? chartData.netWorth : [],
    }
}

export default useChartData
