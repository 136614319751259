import React from 'react'
import { Transaction } from '../types'
import { TransactionsContainerCombinedProps } from './Transactions'
import { useLinkSuggestions } from '../hooks/useLinkSuggestions'
import { useAccounts } from '../hooks/useAccounts'

export type LinkSuggestionsContainerProps = {
    accountId: string
    transaction: Transaction
    listTransactions: {(): void}
    getTransactionById: {(): void}
}

export type WithLinkSuggestionsProps = {
    transaction: Transaction
    updateValues: Partial<Transaction>
    onPairedTransactionSelect: {(transferPair: string): void}
    Layout: React.ElementType
}

export type LinkSuggestionsCombinedContainerProps = {} & LinkSuggestionsContainerProps &
    TransactionsContainerCombinedProps &
    TransactionsContainerCombinedProps

export type LinkSuggestionsType = {
    linkableTransactions: Transaction[]
}

export const LinkSuggestionsContainer = ({ Layout, updateValues, ...props }: WithLinkSuggestionsProps) => {
    const { list: accounts } = useAccounts()
    const { transactions } = useLinkSuggestions({ updateValues })

    return <Layout linkableTransactions={transactions} accounts={accounts} {...props} />
}

export default LinkSuggestionsContainer
