import { Category } from '../../types'
import firebase, { firestore } from 'firebase'

export function getCategoryDoc(userId: string, id: string): firestore.DocumentReference<Category> | undefined {
    const doc = firestore()
        .collection('users')
        .doc(userId)
        .collection('categories')
        .doc(id || '0')

    return doc as firestore.DocumentReference<Category>
}

export async function getCategory(id: string): Promise<Category | undefined> {
    const user = firebase.auth().currentUser
    const doc = getCategoryDoc(user.uid, id)

    return { uid: doc.id, ...(await doc.get()).data() }
}
