import React from 'react'
import { Row, Col, Spin } from 'antd'

export const Loading = () => (
    <Row>
        <Col md={{ span: 6, push: 3 }}>
            <div className="page-is-loading">
                <Spin spinning delay={100} size="large" tip="Loading..." />
            </div>
        </Col>
    </Row>
)

export default Loading
