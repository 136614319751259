import { toDate, format } from 'date-fns'
import { LookupItem } from '../types'

export const convertArrayToIdMap = <T extends LookupItem>(items: T[]): { [x: string]: T } => {
    const map: { [x: string]: T } = {}
    items.forEach(item => (map[item.uid] = item))

    return map
}

export const reverse = (original: string | undefined): string => {
    return original
        ? original
            .split('')
            .reverse()
            .join('')
        : ''
}

export const formatTransactionDate = (unixDate: number): string => {
    const date = toDate(unixDate)
    const now = new Date()
    const isCurrentYear = date.getUTCFullYear() === now.getUTCFullYear()

    return isCurrentYear ? format(date, 'MM/dd') : format(date, 'yyyy/MM/dd')
}

export const formatCurrency = (amount: number, currency = 'EUR') => {
    return amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency,
        style: 'currency',
    })
}
