import React from 'react'
import { UpdateAccountContainerProps } from './UpdateAccount.interface'
import { useAccounts } from '../hooks/useAccounts'
import { useUploadTransactions } from '../hooks/useUploadTransactions'

export const UploadTransactionsContainer = ({ Layout }: UpdateAccountContainerProps) => {
    const { list } = useAccounts()
    const [uploadTransactions] = useUploadTransactions()

    return <Layout accounts={list} uploadTransactions={uploadTransactions} />
}

export default UploadTransactionsContainer
