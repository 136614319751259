import React, { useEffect, useState } from 'react'
import { toDate, format, getTime } from 'date-fns'
import { FieldProps, useFormikContext } from 'formik'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Input } from 'antd'

type DateProps = {
    placeholder: string
} & FieldProps &
    ReactDatePickerProps

interface DateState {
    value?: number
    display?: string
    [x: string]: any
}

export const DateInput = ({ field, placeholder, ...props }: DateProps) => {
    const { setFieldValue } = useFormikContext<any>()

    const combindedProps = { ...props, ...field }
    const { value } = combindedProps

    const [display, setDisplay] = useState(value && format(toDate(value), 'yyyy-MM-dd'))

    useEffect(() => {
        setDisplay(value && format(toDate(value), 'yyyy-MM-dd'))
    }, [setDisplay, value])

    const handleChange = (date?: Date) => {
        const { name = '' } = { ...props, ...field}
        const newDate = new Date()
        if (date) {
            newDate.setDate(date.getDate())
            newDate.setMonth(date.getMonth())
            newDate.setFullYear(date.getFullYear())
        }
        setFieldValue(name, getTime(newDate))
        setDisplay(newDate ? format(newDate, 'yyyy-MM-dd') : placeholder)
    }

    return (
        <DatePicker
            {...props}
            {...field}
            value={display || placeholder}
            onChange={e => handleChange(e || undefined)}
            customInput={<Input />}
        />
    )
}

export default DateInput
