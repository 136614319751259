import React, { Suspense } from 'react';

import { FirebaseAppProvider, preloadAuth, preloadFirestore, useFirebaseApp } from 'reactfire';

import { firebaseConfig } from './firebase.config'

import Routes from './routes'
import { HashRouter as Router } from 'react-router-dom'

// Load css
import 'antd/dist/antd.css'
import 'simplebar/dist/simplebar.css'
import 'react-datepicker/dist/react-datepicker.css'

import './styles/style.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faEdit,
  faTimes,
  faTrash,
  faHome,
  faChartPie,
  faUpload,
  faMoneyBill,
  faQuestion,
  faEllipsisV,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase';

library.add(
  faTrash,
  faEdit,
  faTimes,
  faCheck,
  faHome,
  faChartPie,
  faUpload,
  faMoneyBill,
  faQuestion,
  faEllipsisV,
  faPowerOff
)

const preloadSDKs = firebaseApp => {
  return Promise.all([
    preloadFirestore({
      firebaseApp,
      setup(firestore) {
        return firestore().enablePersistence();
      }
    }),
    preloadAuth({ firebaseApp }),
  ]);
};



function App() {
  const firebaseApp = useFirebaseApp()
  preloadSDKs(firebaseApp)

  return (
      <Suspense fallback={<h3>Loading...</h3>}>
        <React.StrictMode>
          <Router>
            <Routes />
          </Router>
        </React.StrictMode>
      </Suspense>
  );
}

function AppWrapper() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <App/>
    </FirebaseAppProvider>
  )
}

export default AppWrapper
