export const firebaseConfig = {
    apiKey: "AIzaSyCyxEVdlKFD_72qTrO4Q8Lyh-Ah2QkuRUY",
    authDomain: "giglab-money.firebaseapp.com",
    databaseURL: "https://giglab-money.firebaseio.com",
    projectId: "giglab-money",
    storageBucket: "giglab-money.appspot.com",
    messagingSenderId: "557812934828",
    appId: "1:557812934828:web:32b6346e267051a9c31709",
    measurementId: "G-TKMVMQZ6MZ"
}
