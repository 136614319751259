import React from 'react'
import { Autocomplete, AutocompleteProps } from './Autocomplete'
import { Account } from '../../types'
import { useAccounts } from '../../hooks/useAccounts'

export type AccountSelectProps = {} & AutocompleteProps<Account>

//  TODO: make autocomplete not creatable
export const AccountSelect = ({ accounts, ...props }: AccountSelectProps) => (
    <Autocomplete options={accounts} placeholder="Account" {...props} />
)

export const AccountSelectWithQuery = props => {
    const { list: accounts } = useAccounts()
    return <AccountSelect accounts={accounts} {...props} />
}
