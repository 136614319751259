import { Tag } from '../types'
import { convertArrayToIdMap } from '../lib/utils'
import useUserSpecificCollection from './useUserSpecificCollection'

export const useTags = (): { list: Tag[], loading: boolean, error?: string, map: {[x: string]: Tag} } => {
    const list = useUserSpecificCollection<Tag>('tags')
    const map = Array.isArray(list) ? convertArrayToIdMap(list) : {}

    return { list, map, loading: false, error: null }
}

export default useTags
