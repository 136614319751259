import React from 'react'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { Menu } from 'antd'
import Error from './Error'

const AccountGroupTitle = ({ group, groupTotal }) => (
    <div
        style={{
            backgroundColor: '#212121',
            border: 'none',
            padding: '10px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
        }}
    >
        <div style={{ display: 'inline-block', width: '65%', color: 'white', fontWeight: 600 }}>{group}</div>
        <div
            style={{
                textAlign: 'right',
                display: 'inline-block',
                width: '35%',
                color: 'white',
                fontWeight: 600,
            }}
        >
            {groupTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                currency: 'USD',
                style: 'currency',
            })}
        </div>
    </div>
)

export const AccountGroup = ({ group, accounts, groupTotal, ...props }) => (
    <Menu.ItemGroup
        // style={{ borderRadius: '5px', margin: '20px', border: '1px solid black' }}
        title={<AccountGroupTitle group={group} groupTotal={groupTotal} />}
        {...props}
    >
        <AccountGroupItems accounts={accounts} {...props} />
    </Menu.ItemGroup>
)

export const AccountGroupItems = ({ accounts, ...props }) =>
    accounts.map(item => (
        <Menu.Item key={item.uid} style={{ backgroundColor: '#484848', border: 'none' }} {...props}>
            <Link to={`/accounts/${item.uid}/transactions`} tabIndex={-1}>
                <div style={{ display: 'inline-block', width: '65%', color: 'white' }} title={item.name}>
                    {'   '}
                    {item.name || (item.uid.split(':')[1] && item.uid.split(':')[1].replace('_', ' '))}
                </div>
                <div style={{ textAlign: 'right', display: 'inline-block', width: '35%', color: 'white' }}>
                    {item.balance &&
                        item.balance.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            currency: item.currency || 'EUR',
                            style: 'currency',
                        })}
                </div>
            </Link>
        </Menu.Item>
    ))

export const Accounts = ({ groupedAccounts }) => {
    const { match } = useReactRouter<{ accountId: string }>()
    // if (error) {
    //     return <Error content={error} />
    // }

    let total = 0;

    const groups = Object.keys(groupedAccounts).map(groupName => {
        const groupProps = groupedAccounts[groupName]
        total += groupProps.total
        return (
            <AccountGroup
                key={groupName}
                group={groupName}
                accounts={groupProps.accounts}
                groupTotal={groupProps.total}
            />
        )
    })

    return (
        <div>
            <Menu
                mode="inline"
                style={{ backgroundColor: 'black' }}
                // className={loading ? 'content-loading' : ''}
                selectedKeys={match.params.accountId ? [match.params.accountId] : undefined}
            >
                <Menu.ItemGroup
                    title={<AccountGroupTitle group="Net" groupTotal={total} />}
                />
                {groups}
            </Menu>
        </div>
    )
}
