import { Transaction } from '../types'
import { useUser, useFirestoreDocData } from 'reactfire'
import { User } from 'firebase'
import { getTransactionDoc } from '../lib/db/transactions'

export function useTransaction(accountId: string, id: string): Transaction | undefined {
    const user = useUser<User>()
    const transactionDoc = getTransactionDoc(user.uid, accountId, id)

    return useFirestoreDocData<Transaction>(transactionDoc, {idField: 'uid'})
}

export default useTransaction
