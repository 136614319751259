import React from 'react'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik'
import { Button } from 'antd'
import { DateInput } from './form/Date'
import { UpdateTransactionProps } from '../containers/UpdateTransaction.interface'
import { Transaction } from '../types'
import TagSelect from './form/TagSelect'
import PayeeSelect from './form/PayeeSelect'
import CategorySelect from './form/CategorySelect'
import LinkSuggestionsContainer from '../containers/LinkSuggestions'
import LinkSuggestions from './LinkSuggestions'

const initialValues: Partial<Transaction> = {
    accountId: '',
    charged: 0,
    amount: 0,
    notes: null,
    payee: null,
    category: null,
    tag: null,
    toAccount: null,
}

export const UpdateTransaction = ({ transaction, onSubmit, onClose, onPairedTransactionSelect }: UpdateTransactionProps) => (
    <Formik
        initialValues={{ ...initialValues, ...transaction }}
        enableReinitialize={false}
        onSubmit={(values, actions) => {
            const submittedTransaction = { ...values }
            submittedTransaction.amount = values.amount && parseFloat((values.amount as unknown) as string)
            onSubmit(submittedTransaction)
            // @ts-ignore
            actions.resetForm(initialValues)
        }}
    >
        {({ isSubmitting, values, resetForm, setFieldValue, handleSubmit }: FormikProps<Transaction>) => [
            <Form key="form" className="edit-transaction-form">
                <Field
                    as={DateInput}
                    name="charged"
                    placeholder="YYYY/MM/DD"
                    tabIndex={onClose ? 8 : 0}
                    className={`transaction-date${onClose ? ' inline' : ''}`}
                />
                <ErrorMessage name="charged" component="div" />
                <Field
                    as={PayeeSelect}
                    name="payee"
                    placeholder="Payee"
                    className={`field-autocomplete transaction-payee${onClose ? ' inline' : ''}`}
                    tabIndex={onClose ? 9 : 0}
                />
                <ErrorMessage name="payee" component="div" />
                <Field
                    as={CategorySelect}
                    name="category"
                    placeholder="Category"
                    className={`field-autocomplete transaction-category${onClose ? ' inline' : ''}`}
                    tabIndex={onClose ? 1 : 0}
                    value={values.toAccount || values.category}
                />
                <ErrorMessage name="category" component="div" />
                <Field
                    as={TagSelect}
                    name="tag"
                    placeholder="Tag"
                    className={`field-autocomplete transaction-tag${onClose ? ' inline' : ''}`}
                    tabIndex={onClose ? 1 : 0}
                />
                <ErrorMessage name="tag" component="div" />
                <Field
                    type="text"
                    name="notes"
                    placeholder="Notes"
                    className="field-text transaction-notes"
                    tabIndex={onClose ? 1 : 0}
                    value={values.notes || ''}
                    onChange={e => setFieldValue('notes', e.currentTarget.value || null)}
                />
                <ErrorMessage name="notes" component="div" />
                <Field
                    type="text"
                    name="amount"
                    style={{ width: '100px', marginRight: '7px' }}
                    placeholder="Amount"
                    className="field-text transaction-amount"
                    tabIndex={onClose ? 1 : 0}
                />
                <ErrorMessage name="amount" component="div" />
                <div className="transaction-balance" />
                <div className="transaction-controls">
                    <Button
                        type="primary"
                        disabled={isSubmitting}
                        tabIndex={onClose ? 1 : 0}
                        onClick={(handleSubmit as unknown) as React.MouseEventHandler<HTMLElement>}
                    >
                        <FontAwesomeIcon icon="check" size="sm" />
                    </Button>
                    {!isEmpty(transaction) && (
                        <span style={{ paddingTop: '10px', paddingLeft: '10px' }} onClick={onClose}>
                            <FontAwesomeIcon icon="times" size="sm" />
                        </span>
                    )}
                    {isEmpty(transaction) && (
                        <span style={{ paddingTop: '10px', paddingLeft: '10px' }} onClick={() => resetForm()}>
                            <FontAwesomeIcon icon="times" size="sm" />
                        </span>
                    )}
                </div>
            </Form>,
            (transaction && values.toAccount) && (
                <LinkSuggestionsContainer
                    key="links"
                    // @ts-ignore
                    Layout={LinkSuggestions}
                    transaction={transaction}
                    updateValues={values}
                    onPairedTransactionSelect={onPairedTransactionSelect}
                />
            ),
        ]}
    </Formik>
)

export default UpdateTransaction
