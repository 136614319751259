import React from 'react'
import { TemplateSidebar } from '../components/TemplateSidebar'


export const Home = () => {
    return (
        <TemplateSidebar>
        </TemplateSidebar>
    )
}

export default Home
