import React from 'react'
import { TemplateSidebar } from '../components/TemplateSidebar'
import { UpdateAccount } from '../components/UpdateAccount'
import { UpdateAccountContainer } from '../containers/UpdateAccount'

export type UrlMatch = {
    params: {
        accountId: string
    }
}

export type EditAccountProps = {
    match: UrlMatch
}

export type EditAccountPageType<T> = {
    Layout: React.ComponentType<T>
    accountId: string
}

export const UpdateAccountPage = (props: EditAccountProps) => (
    <TemplateSidebar>
        <UpdateAccountContainer
            Layout={UpdateAccount}
            {...props}
        />
    </TemplateSidebar>
)

export default UpdateAccountPage
