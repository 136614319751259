import { Payee } from '../types'
import { convertArrayToIdMap } from '../lib/utils'
import useUserSpecificCollection from './useUserSpecificCollection'

export const usePayees = (): { list: Payee[], loading: boolean, error?: string, map: {[x: string]: Payee} } => {
    const list = useUserSpecificCollection<Payee>('payees')
    const map = Array.isArray(list) ? convertArrayToIdMap(list) : {}

    return { list, map, loading: false, error: null }
}

export default usePayees
