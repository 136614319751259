import { useState } from 'react'
import { SelectAllRowsHandler, RowSelectedToggleHandler } from './useRowsSelector.interface'

export const useRowsSelector = <T = string>() => {
    const [allRowsSelected, setAllRowsSelected] = useState<boolean | undefined>(false)
    const [selectedRows, setSelectedRows] = useState<T[]>([])

    const handleToggle: RowSelectedToggleHandler = (row: any) => {
        const newSelected = [...selectedRows]
        const index = newSelected.indexOf(row)
        if (index >= 0) {
            newSelected.splice(index, 1)
        } else {
            newSelected.push(row)
        }
        setSelectedRows(newSelected)
        setAllRowsSelected(false)
    }

    const handleSelectAllRows: SelectAllRowsHandler = (items: T[]) => {
        if (!allRowsSelected) {
            setSelectedRows([...items])
        } else {
            setSelectedRows([])
        }
        setAllRowsSelected(!allRowsSelected)
    }

    const isRowSelected = (item: T) => {
        return selectedRows.indexOf(item) >= 0
    }

    const deselectRows = (rows: T[]) => {
        const newSelected = selectedRows.filter(item => rows.indexOf(item) < 0)
        setSelectedRows(newSelected)
        setAllRowsSelected(false)
    }

    const deselectAllRows = () => {
        setSelectedRows([])
        setAllRowsSelected(false)
    }

    return {
        selectedRows,
        allRowsSelected,
        onRowSelectedToggle: handleToggle,
        onSelectAllRowsToggle: handleSelectAllRows,
        isRowSelected,
        deselectRows,
        deselectAllRows,
        setSelectedRows,
    }
}

export default useRowsSelector
