import React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import * as utils from '../lib/utils'
import { Transaction } from '../types'

const TransactionColumn = styled.div`
    justify-content: left;
    width: 20%;
`

type LinkSuggestionProps = {
    transaction: Transaction
    accountCurrency?: string
    onSelect: any
}

// TODO: come up with better naming scheme
export const LinkSuggestion = ({ transaction, accountCurrency, onSelect }: LinkSuggestionProps) => (
    <div key={`${transaction.charged}`} style={{ display: 'flex', paddingLeft: '200px' }}>
        <TransactionColumn style={{ width: '10px' }}>
            <Input type="radio" name="link" onClick={() => onSelect(transaction) } />
        </TransactionColumn>
        <TransactionColumn className="transaction-date">
            {transaction.charged ? utils.formatTransactionDate(transaction.charged) : 'ERROR: no date provided'}
        </TransactionColumn>
        <TransactionColumn className="transaction-amount">
            {transaction.amount}{' '}
            {transaction.amount && transaction.currency !== 'unknown' ? transaction.currency : accountCurrency}
        </TransactionColumn>
        <TransactionColumn className="transaction-notes" title={transaction.notes}>
            {transaction.notes}
        </TransactionColumn>
    </div>
)

export default LinkSuggestion
