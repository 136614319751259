import React from 'react'
import { TemplateSidebar } from '../components/TemplateSidebar'
import Error from '../components/Error'

export const ErrorPage = props => (
    <TemplateSidebar>
        <Error title="404" content="Sorry, the route you requested does not exist" {...props} />
    </TemplateSidebar>
)

export default ErrorPage
