import React from 'react'
import { Field, useFormikContext } from 'formik'

export const Checkbox = ({ name, ...props }) => {
    const { setFieldValue } = useFormikContext<any>()
    return (
        <Field name={name}>
            {({ field }) => (
                <label>
                    <input
                        type="checkbox"
                        {...props}
                        checked={field.value}
                        onChange={() => {
                            const nextValue = !field.value
                            setFieldValue(name, nextValue)
                        }}
                    />
                    {props.value}
                </label>
            )}
        </Field>
    )
}

export default Checkbox
