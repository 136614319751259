import { Transaction } from '../types'
import useDeleteTransaction from './useDeleteTransaction'
import useUpdateTransaction from './useUpdateTransaction'

export const moveTransactions = async (
    transactions: Transaction[],
    newAccount: string,
    updateTransaction: Function,
    deleteTransaction: Function
): Promise<Transaction[]> => {
    if (!newAccount) {
        throw Error('moveTransaction is called without account argument')
    }
    const promises = transactions.map(transaction =>
        updateTransaction({ ...transaction, account: newAccount }).then(() => deleteTransaction(transaction.accountId, transaction.uid))
    )
    const result = await Promise.all(promises)

    // @ts-ignore
    return result
}

export function useMoveTransactions() {
    const [updateTransaction] = useUpdateTransaction()
    const [deleteTransaction] = useDeleteTransaction()

    return [
        (transactions: Transaction[], account: string) =>
            moveTransactions(transactions, account, updateTransaction, deleteTransaction),
    ]
}
