import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, FormikProps, ErrorMessage, Field } from 'formik'
import { Button, Layout, PageHeader, Form, Input } from 'antd'
import { Checkbox } from './form/Checkbox'
import { Account } from '../types'

type UpdateAccountProps = { accountId: string }

// @ts-ignore
export const UpdateAccount = ({ accountId, accounts, updateAccount }: UpdateAccountProps) => {
    const account = accounts.find(item => item.uid === accountId) || {}
    if (!account) {
        return <div />
    }

    return (
        <Layout>
            <PageHeader
                title={
                    <span>
                        <Link to={`/accounts/${account.uid}/transactions`}>
                            <span>{'[<-]'}</span>
                        </Link>
                        Edit Account
                    </span>
                }
            />

            <Layout.Content>
                <Formik
                    initialValues={account}
                    enableReinitialize
                    // @ts-ignore
                    onSubmit={({ total, uid, user, ...input }) => {
                        console.log('form submitted!', uid, input)
                        // @ts-ignore
                        updateAccount({ uid, input })
                    }}
                >
                    {({ isSubmitting, handleSubmit, values, setFieldValue }: FormikProps<Account>) => (
                        <Form onFinish={handleSubmit}>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    as={Input}
                                    value={values.name || ''}
                                    onChange={e => setFieldValue('name', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="name" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="currency"
                                    placeholder="Currency"
                                    as={Input}
                                    value={values.currency || ''}
                                    onChange={e => setFieldValue('currency', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="currency" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="isArchived" />
                                <span>Is Archived</span>
                                <ErrorMessage name="isArchived" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Checkbox name="isExcluded" />
                                <span>Is Excluded</span>
                                <ErrorMessage name="isExcluded" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="group"
                                    placeholder="Group"
                                    as={Input}
                                    value={values.group || ''}
                                    onChange={e => setFieldValue('group', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="group" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="provider"
                                    placeholder="Provider"
                                    as={Input}
                                    value={values.provider || ''}
                                    onChange={e => setFieldValue('provider', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="provider" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="seConnectionId"
                                    placeholder="Saltedge Connection Id"
                                    as={Input}
                                    value={values.seConnectionId || ''}
                                    onChange={e => setFieldValue('seConnectionId', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="seConnectionId" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="seAccountId"
                                    placeholder="Saltedge Account Id"
                                    as={Input}
                                    value={values.seAccountId || ''}
                                    onChange={e => setFieldValue('seAccountId', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="seAccountId" component="div" />
                            </Form.Item>
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="seStartWith"
                                    placeholder="Saltedge Start Import With"
                                    as={Input}
                                    value={values.seStartWith || ''}
                                    onChange={e => setFieldValue('seStartWith', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="seStartWith" component="div" />
                            </Form.Item>                            
                            <Form.Item>
                                <Field
                                    type="text"
                                    name="balance"
                                    placeholder="Balance"
                                    as={Input}
                                    value={values.balance || ''}
                                    onChange={e => setFieldValue('balance', e.currentTarget.value || null)}
                                />
                                <ErrorMessage name="balance" component="div" />
                            </Form.Item>
                            <Button htmlType="submit" type="primary" disabled={isSubmitting}>
                                Save
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Layout.Content>
        </Layout>
    )
}

export default UpdateAccount
