import React from 'react'
import { ChartsContainer } from '../containers/Charts'
import { Charts } from '../components/Charts'
import { TemplateSidebar } from '../components/TemplateSidebar'

export const ChartsPage = props => (
    <TemplateSidebar>
        <ChartsContainer {...props} Layout={Charts} />
    </TemplateSidebar>
)

export default ChartsPage
