import { Account } from '../types'
import { useUser, useFirestoreDocData } from 'reactfire'
import { User } from 'firebase'
import { getAccountDoc } from '../lib/db/accounts'

export function useAccount(id: string): Account | undefined {
    const user = useUser<User>()
    const accountDoc = getAccountDoc(user.uid, id)

    return useFirestoreDocData<Account>(accountDoc, {idField: 'uid'})
}

export default useAccount
