import React from 'react'
import { Layout, PageHeader, Input } from 'antd'
import {
    startOfWeek,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    addDays,
    addWeeks,
    addMonths,
    addQuarters,
    addYears,
    format,
} from 'date-fns'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryContainer,
    VictoryGroup,
    VictoryPie,
    VictoryTheme,
    VictoryTooltip,
} from 'victory'
import Select from 'react-select'
import { Account, Category } from '../types'
import { ChartData } from '../hooks/useChartData'

const FiltersContainer = styled.div``

type ChartsProps = {
    accounts: Account[]
    selectedAccounts: string[]
    categories: string[]
    categoriesMap: { [x: string]: Category }
    chartData: ChartData
    cashflowAverages: { income: number; expense: number }
    onDateFromChange: { (date: Date | null) }
    onDateToChange: { (date: Date | null) }
    onGroupByChange: { (groupBy: string) }
    onAccountsChange: { (accounts: string[] | null) }
    dateFrom: Date
    dateTo: Date
    groupBy: string
    focusedInput: 'startDate' | 'endDate' | null
    onFocusChange: (focusedInput: 'startDate' | 'endDate' | null) => void
}

type FilterValues = {
    text: string
    startDate: Date
    endDate: Date
}

// xxx
// const defaultEvents: EventPropTypeInterface = [
//     {
//         target: 'data',
//         eventHandlers: {
//             onMouseOver: () => {
//                 return [
//                     {
//                         target: 'data',
//                         mutation: () => ({ style: { fill: 'gold' } }),
//                     },
//                     {
//                         target: 'labels',
//                         mutation: () => ({ active: true }),
//                     },
//                 ]
//             },
//             onMouseOut: () => {
//                 return [
//                     {
//                         target: 'data',
//                         mutation: () => {},
//                     },
//                     {
//                         target: 'labels',
//                         mutation: () => ({ active: false }),
//                     },
//                 ]
//             },
//         },
//     },
// ]

const today = new Date()
const weekStart = startOfWeek(today)
const monthStart = startOfMonth(today)
const quarterStart = startOfQuarter(today)
const yearStart = startOfYear(today)
const presets: FilterValues[] = [
    {
        text: 'This Week',
        startDate: weekStart,
        endDate: today,
    },
    {
        text: 'Last Week',
        startDate: addWeeks(weekStart, -1),
        endDate: addDays(weekStart, -1),
    },
    {
        text: 'Last 7 Days',
        startDate: addDays(today, -7),
        endDate: today,
    },
    {
        text: 'This Month',
        startDate: monthStart,
        endDate: today,
    },
    {
        text: 'Last Month',
        startDate: addMonths(monthStart, -1),
        endDate: addDays(monthStart, -1),
    },
    {
        text: 'Last 30 Days',
        startDate: addDays(today, -30),
        endDate: today,
    },
    {
        text: 'This Quarter',
        startDate: quarterStart,
        endDate: today,
    },
    {
        text: 'Last Quarter',
        startDate: addQuarters(quarterStart, -1),
        endDate: addDays(quarterStart, -1),
    },
    {
        text: 'Last 90 Days',
        startDate: today,
        endDate: addDays(today, -90),
    },
    {
        text: 'This Year',
        startDate: yearStart,
        endDate: today,
    },
    {
        text: 'Last Year',
        startDate: addYears(yearStart, -1),
        endDate: addDays(yearStart, -1),
    },
    {
        text: 'Last 365 Days',
        startDate: addDays(today, -365),
        endDate: today,
    },
]

export const Charts = ({
    accounts,
    dateFrom,
    dateTo,
    groupBy,
    categories,
    categoriesMap,
    chartData,
    onDateFromChange,
    onDateToChange,
    onAccountsChange,
    onGroupByChange,
    cashflowAverages,
}: ChartsProps) => (
    <Layout>
        <PageHeader
            title="Charts"
            extra={
                <FiltersContainer>
                    <DatePicker
                        customInput={<Input />}
                        onChange={onDateFromChange}
                        value={format(dateFrom, 'yyyy-MM-dd')}
                    />
                    <DatePicker
                        customInput={<Input />}
                        onChange={onDateToChange}
                        value={format(dateTo, 'yyyy-MM-dd')}
                    />
                    <div style={{ width: '400px', display: 'inline-block' }}>
                        <Select
                            options={presets}
                            defaultValue={presets[5]}
                            getOptionLabel={option => option.text}
                            getOptionValue={option => `${presets.indexOf(option)}`}
                            placeholder="Custom"
                            onChange={value => {
                                if (!value || Array.isArray(value)) return

                                const { startDate, endDate } = value as FilterValues
                                onDateFromChange(startDate)
                                onDateToChange(endDate)
                            }}
                        />
                    </div>
                    <div style={{ width: '100px', display: 'inline-block' }}>
                        <Select
                            options={[
                                { value: 'day' },
                                { value: 'month' },
                                { value: 'year' }
                            ]}
                            getOptionLabel={option => option.value}
                            defaultValue={{ value: groupBy }}
                            onChange={value => {
                                if (!value) return

                                onGroupByChange(value.value)
                            }}
                        />
                    </div>
                    <div style={{ width: '400px', display: 'inline-block' }}>
                        <Select<Account>
                            options={accounts}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.uid}
                            placeholder="All"
                            //@ts-ignore
                            isMulti={true}
                            onChange={value => {
                                if (!value) return

                                //@ts-ignore
                                onAccountsChange(value.map(item => item.uid))
                            }}
                        />
                    </div>
                </FiltersContainer>
            }
        />
        <Layout.Content>
            <div style={{ textAlign: 'center' }}>
                <b>
                    <p>
                        {'Average Expense: '}
                        {Math.abs(cashflowAverages.expense).toLocaleString(undefined, {
                            currency: 'USD',
                            style: 'currency',
                        })}
                    </p>
                    <p>
                        {'Average Income: '}
                        {cashflowAverages.income.toLocaleString(undefined, { currency: 'USD', style: 'currency' })}
                    </p>
                </b>
            </div>
            <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
                <VictoryChart
                    theme={VictoryTheme.material}
                    domainPadding={30}
                    containerComponent={<VictoryContainer responsive />}
                    width={1000}
                    height={400}
                >
                    <VictoryAxis />
                    <VictoryAxis dependentAxis />
                    <VictoryGroup offset={25} colorScale="qualitative">
                        <VictoryBar
                            data={chartData.income || []}
                            x={d => d.x.split('T')[0]}
                            y={d => d.y}
                            labelComponent={<VictoryTooltip activateData orientation="top" />}
                            // events={defaultEvents}
                            labels={({ datum }) => `${datum.x}: ${datum.y}`}
                            barWidth={20}
                        />
                        <VictoryBar
                            data={chartData.expense || []}
                            x={d => d.x.split('T')[0]}
                            y={d => -d.y}
                            labelComponent={<VictoryTooltip activateData orientation="top" />}
                            // events={defaultEvents}
                            labels={({ datum }) => `${datum.x}: ${datum.y}`}
                            barWidth={20}
                        />
                    </VictoryGroup>
                </VictoryChart>
                <VictoryPie
                    containerComponent={<VictoryContainer responsive style={{ width: '80%', margin: '0 auto' }} />}
                    theme={VictoryTheme.material}
                    innerRadius={100}
                    data={chartData.totalsByCategory}
                    y={d => -d.y}
                    x={d => d.x || 'without category'}
                    // xxx
                    // sortKey="y"
                    // events={defaultEvents}
                    labels={({ datum }) =>
                        (categoriesMap && categoriesMap[datum.x] && `${categoriesMap[datum.x].name} ${datum.y}`) ||
                        `without category ${datum.y}`
                    }
                    labelRadius={150}
                    labelComponent={<VictoryTooltip />}
                    width={500}
                    height={500}
                />
                <VictoryChart
                    theme={VictoryTheme.material}
                    domainPadding={30}
                    containerComponent={<VictoryContainer responsive />}
                    width={1000}
                    height={400}
                >
                    <VictoryAxis style={{ tickLabels: { angle: 45 } }} />
                    <VictoryAxis dependentAxis />
                    <VictoryGroup offset={25} colorScale="qualitative">
                        <VictoryBar
                            data={chartData.netWorth || []}
                            x={d => d.x.split('T')[0]}
                            y={d => d.y}
                            labelComponent={<VictoryTooltip activateData orientation="top" />}
                            // events={defaultEvents}
                            labels={({ datum }) => `${datum.x}: ${datum.y}`}
                            barWidth={20}
                        />
                    </VictoryGroup>
                </VictoryChart>
            </div>
        </Layout.Content>
    </Layout>
)

export default Charts
