import React, { Component, useEffect } from 'react'
import { TransactionsContainer } from '../containers/Transactions'
import { TemplateSidebar } from '../components/TemplateSidebar'
import { Transactions } from '../components/Transactions'
import { useAccount } from '../hooks/useAccount'
import { Loading } from '../components/Loading'

export interface UrlMatch {
    params: {
        accountId: string
    }
}

export interface AccountTransactionsPageProps {
    match: UrlMatch
}

export interface AccountTransactionsPageType {
    Layout: Component
    accountId: string
}

export const AccountTransactionsPage = ({
    match: {
        params: { accountId },
    },
}: AccountTransactionsPageProps) => {
    const account = useAccount(accountId)

    useEffect(() => {
        document.title = `Finance - ${account.name}`;
    }, [account]);

    if (!account) {
        return (
            <TemplateSidebar>
                <Loading />
            </TemplateSidebar>
        )
    }
    
    return (
        <TemplateSidebar>
            <TransactionsContainer
                Layout={Transactions}
                account={account}
            />
        </TemplateSidebar>
    )
}

export default AccountTransactionsPage
