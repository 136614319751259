import { Account } from '../types'
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'
import { User } from 'firebase'
import { convertArrayToIdMap } from '../lib/utils'

export const useAccounts = (): { list: Account[], loading: boolean, error?: string, map: {[x: string]: Account} } => {
    const user = useUser<User>()
    const collection = useFirestore().collection('users').doc(user.uid).collection('accounts').where('isArchived', '==', '')
    const accounts = useFirestoreCollectionData<Account>(collection, {idField: 'uid'})

    const map = convertArrayToIdMap(accounts)

    return { list: accounts, map, loading: false, error: null }
}

export default useAccounts
