import { useFirestore, useUser } from 'reactfire'
import { User } from 'firebase'

export function useUpdateTransaction() {
    const firestore = useFirestore()
    const user = useUser<User>()

    async function updateTransaction(options) {
        const { accountId, uid } = options

        const transactionsCollection = firestore
            .collection('users')
            .doc(user.uid)
            .collection('accounts')
            .doc(accountId)
            .collection('transactions')

        console.log(`Updating transaction uid=${uid}, options=${JSON.stringify(options)}`)

        const doc = (uid == 0 || !uid) ? transactionsCollection.doc() : transactionsCollection.doc(uid)
        await doc.set({...options, uid: doc.id})

        return doc
    }

    return [updateTransaction]
}

export default useUpdateTransaction
