import React, { useState } from 'react'
import { Transaction } from '../types'
import { UpdateTransactionContainerProps } from './UpdateTransaction.interface'
import { useUpdateTransaction } from '../hooks/useUpdateTransaction'
import { useRouteMatch } from 'react-router-dom'
import useAccount from '../hooks/useAccount'
import useTransaction from '../hooks/useTransaction'
import { useUser } from 'reactfire'
import { User } from 'firebase'
import { getTransaction } from '../lib/db/transactions'

export const UpdateTransactionContainer = ({
    onEditorToggle,
    closeEnabled,
    Layout,
    transaction,
}: UpdateTransactionContainerProps) => {
    const { params: { accountId, transactionId } } = useRouteMatch<{ accountId: string, transactionId: string }>()
    const account = useAccount(accountId)
    const routeTransaction = useTransaction(accountId, transactionId)
    const [updateTransaction] = useUpdateTransaction()
    const [transferPair, setTransferPair] = useState<string>()
    // TODO: replace with something more abstract
    const user = useUser<User>()

    const getPairedTransaction = async ({ uid, toAccount, accountId, amount, source, ...other }: Transaction) => {
        if (!transferPair) {
            return null
        }

        // TODO: handle different currency accounts?

        let pairedTransaction: Partial<Transaction>

        if (transferPair == 'new-transaction') {
            pairedTransaction = {
                ...other,
                accountId: toAccount,
                toAccount: accountId,
                amount: -amount,
                pairedTransactionId: uid,
            }
        } else {
            pairedTransaction = await getTransaction(user.uid, toAccount, transferPair)

            pairedTransaction.pairedTransactionId = uid
            pairedTransaction.toAccount = accountId
        }

        const paierdTransactionDoc = await updateTransaction(pairedTransaction)

        //TODO: is it a good idea to leak through docs?
        return paierdTransactionDoc.id
    }

    const handleSubmit = async (submittedTransaction: Transaction) => {
        // TODO: validate that account is owned by user in case accountId is empty?
        const newTransaction: Transaction = {
            ...submittedTransaction,
            accountId: account.uid || submittedTransaction.accountId,
            source: submittedTransaction.source || 'manual',
            currency: submittedTransaction.currency || account.currency,
        }

        const pairedTransactionId = await getPairedTransaction(newTransaction)
        if (pairedTransactionId) {
            newTransaction.pairedTransactionId = pairedTransactionId
        }

        await updateTransaction(newTransaction)

        onEditorToggle && onEditorToggle('')
    }

    const handleClose = () => {
        return closeEnabled ? onEditorToggle && onEditorToggle('') : undefined
    }

    const handlePairedTransactionSelect = (transferPair: string) => {
        console.log(`Selected new transfer pair ${transferPair}`)
        setTransferPair(transferPair)
    }

    const formTransaction: Partial<Transaction> = transaction || routeTransaction || {}

    return <Layout transaction={formTransaction} onSubmit={handleSubmit} onClose={handleClose} onPairedTransactionSelect={handlePairedTransactionSelect} />
}

export default UpdateTransactionContainer
