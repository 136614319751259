import React from 'react'
import { UpdateAccountContainerProps } from './UpdateAccount.interface'
import { useAccounts } from '../hooks/useAccounts'
import { useUpdateAccount } from '../hooks/useUpdateAccount'
import { useRouteMatch } from 'react-router-dom'

export function UpdateAccountContainer({ Layout, ...props }: UpdateAccountContainerProps) {
    const { params: { accountId } } = useRouteMatch<{ accountId: string }>()
    const { list: accounts } = useAccounts()
    const [updateAccount] = useUpdateAccount()

    return <Layout accounts={accounts} accountId={accountId} updateAccount={updateAccount} {...props} />
}

export default UpdateAccountContainer
