import { withFormik } from 'formik'
import React, { useState, ComponentType } from 'react'
import { WithRowsSelectorType } from '../hooks/useRowsSelector.interface'
import { useMoveTransactions } from '../hooks/useMoveTransactions'
import { Transaction } from '../types'
import useDeleteTransaction from '../hooks/useDeleteTransaction'

type Props = {
    Layout: ComponentType<any>
    accountId: string
} & WithRowsSelectorType<Transaction>

const TransactionBulkUpdateControls = ({ selectedRows, Layout, deselectAllRows, setSelectedRows }: Props) => {
    const [deleteTransaction] = useDeleteTransaction()
    const [moveTransactions] = useMoveTransactions()
    const [account, setAccount] = useState('')

    const handleAccountSelect = (newAccount: string) => {
        setAccount(newAccount)
    }

    const handleMoveTransactions = async () => {
        const total = selectedRows.length
        let current = 0

        console.log('running requests...')
        const logMovedResult = transactions => {
            current += transactions.lenght
            transactions.forEach(transaction => console.log(`move ${current}/${total}: ${transaction.uid}`))
        }
        const batchSize = 3
        for (let i = 0; i < selectedRows.length; i += batchSize) {
            const batchRows = selectedRows.slice(i, i + batchSize)
            setSelectedRows(selectedRows.slice(i + batchSize))
            await moveTransactions(batchRows, account).then(() => logMovedResult(batchRows))
            console.log(`move promise batch ${i}-${i + 3}`)
        }
        deselectAllRows()
    }

    const handleDeleteTransactions = async () => {
        const total = selectedRows.length
        let current = 0
        console.log('running requests...')
        const logDeleteResult = transaction => {
            current += 1
            console.log(`deleted ${current}/${total}: ${transaction.uid}`)
        }
        const batchSize = 3
        for (let i = 0; i < selectedRows.length; i += batchSize) {
            const promises: Promise<void>[] = []
            const batchRows = selectedRows.slice(i, i + batchSize)
            batchRows.forEach(transaction => {
                console.log('enter deleteTransaction')
                promises.push(deleteTransaction(transaction.accountId, transaction.uid).then(() => logDeleteResult(transaction)))
            })
            setSelectedRows(selectedRows.slice(i + batchSize))
            console.log(`delete promise batch ${i}-${i + 3}`)
            await Promise.all(promises)
        }
        deselectAllRows()
    }

    return (
        <Layout
            onMoveTransactions={handleMoveTransactions}
            onAccountSelect={handleAccountSelect}
            onDeleteTransactions={handleDeleteTransactions}
            selectedRows={selectedRows}
        />
    )
}

export const TransactionBulkUpdateControlsContainer = withFormik<any, Props>({ handleSubmit: () => null })(
    TransactionBulkUpdateControls
)

export default TransactionBulkUpdateControlsContainer
