import React from 'react'
import _ from 'lodash'
import { Input } from 'antd'
import { TransactionContainer } from '../containers/Transaction'
import { LinkSuggestionsType, LinkSuggestionsCombinedContainerProps } from '../containers/LinkSuggestions'
import { LinkSuggestion } from './LinkSuggestion'
import { Transaction } from '../types'

export type LinkSuggestionsComponentProps = {
    linkableTransactions: Transaction[]
    onPairedTransactionSelect: { (transferPair: string): void }
} & LinkSuggestionsCombinedContainerProps &
    LinkSuggestionsType

// export type TransactionsListingType = {
//     deleteTransaction: DeleteTransactionMutationFn
//     updateTransaction: UpdateTransactionMutationFn
// }

export const LinkSuggestions = ({
    linkableTransactions,
    accountId,
    onPairedTransactionSelect,
    ...props
}: LinkSuggestionsComponentProps) => {
    if (
        !Array.isArray(
            linkableTransactions
        ) /*||
        !Array.isArray(props.payees) ||
        !Array.isArray(props.categories) ||
        !Array.isArray(props.tags)*/
    ) {
        return <span>Loading Transactions...</span>
    }

    return (
        <div className="link-suggestions-container">
            {linkableTransactions.map((item, index) => (
                <TransactionContainer
                    {...props}
                    key={index}
                    transaction={item}
                    hash={item.accountId}
                    range={item.charged}
                    Layout={LinkSuggestion}
                    onSelectAllRowsToggle={() => { }}
                    onInlineEditorToggle={() => { }}
                    accountId={accountId}
                    onSelect={(transaction) => onPairedTransactionSelect(transaction.uid)}
                />
            ))}
            <div style={{ paddingLeft: '200px', display: 'flex' }}>
                <div style={{ width: '10px' }}>
                    <Input type="radio" name="link" onClick={() => onPairedTransactionSelect('new-transaction')} value="new-transaction" />
                </div>
                <div>New Transaction</div>
            </div>
        </div>
    )
}

export default LinkSuggestions
