import React, { useEffect, useState } from 'react'
import { useFirestore, useUser } from 'reactfire'
import { User } from 'firebase'

async function fbGet<T>(firestore: FirebaseFirestore.Firestore, userId: string, collectionName: string, id: string) {
    if (!id) {
        return undefined
    }

    const collection = firestore
        .collection('users')
        .doc(userId)
        .collection(collectionName)

    //@ts-ignore
    const doc = await collection.doc(id).get({ source: 'cache' })

    return doc.data() || id
}

export const TransactionContainer = ({ Layout, transaction, ...props }) => {
    const user = useUser<User>()
    const firestore = useFirestore()
    const [payee, setPayee] = useState()
    const [tag, setTag] = useState()
    const [category, setCategory] = useState()
    const [account, setAccount] = useState()
    const [toAccount, setToAccount] = useState()

    useEffect(() => {
        //@ts-ignore
        fbGet(firestore, user.uid, 'payees', transaction.payee).then(item => setPayee(item))
    }, [transaction.payee])

    useEffect(() => {
        //@ts-ignore
        fbGet(firestore, user.uid, 'tags', transaction.tag).then(item => setTag(item))
    }, [transaction.tag])

    useEffect(() => {
        //@ts-ignore
        fbGet(firestore, user.uid, 'accounts', transaction.accountId).then(item => setAccount(item))
    }, [transaction.accountId])

    useEffect(() => {
        //@ts-ignore
        fbGet(firestore, user.uid, 'accounts', transaction.toAccount).then(item => setToAccount(item))
    }, [transaction.toAccount])

    useEffect(() => {
        //@ts-ignore
        fbGet(firestore, user.uid, 'categories', transaction.category).then(item => setCategory(item))
    }, [transaction.category])

    return (
        <Layout
            transaction={transaction}
            tag={tag}
            account={account}
            category={category}
            payee={payee}
            toAccount={toAccount}
            {...props}
        />
    )
}

export default TransactionContainer
