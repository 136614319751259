import { useFirestore, useUser } from 'reactfire'
import { User } from 'firebase'

export function useUpdateAccount() {
    const firestore = useFirestore()
    const user = useUser<User>()

    function updateAccount({uid, ...options}) {
        const accountsCollection = firestore.collection("users").doc(user.uid).collection("accounts")
        const doc = uid ? accountsCollection.doc(uid) : accountsCollection.doc()

        doc.set({
            isArchived: "",
            ...options.input,
            balance: parseFloat(options.input.balance),
            uid: doc.id,
            user: user.email,
        })
    }

    return [updateAccount]
}

export default useUpdateAccount
