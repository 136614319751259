import { Transaction } from '../../types'
import { firestore } from 'firebase'

export function getTransactionDoc(userId: string, accountId: string, id: string): firestore.DocumentReference<Transaction> | undefined {
    const doc = firestore()
        .collection('users')
        .doc(userId)
        .collection('accounts')
        .doc(accountId)
        .collection('transactions')
        .doc(id || '0')

    return doc as firestore.DocumentReference<Transaction>
}

export async function getTransaction(userId: string, accountId: string, id: string): Promise<Transaction | undefined> {
    const doc = getTransactionDoc(userId, accountId, id)

    return { uid: doc.id, ...(await doc.get()).data() }
}
