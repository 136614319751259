import { useFirestore, useUser } from 'reactfire'
import { User } from 'firebase'

export function useDeleteTransaction() {
    const firestore = useFirestore()
    const user = useUser<User>()

    function deleteTransaction(accountId: string, transactionId: string) {
        const transactionsCollection = firestore
            .collection('users')
            .doc(user.uid)
            .collection('accounts')
            .doc(accountId)
            .collection('transactions')

        // TODO: handle paired transactions

        return transactionsCollection.doc(transactionId).delete()
    }

    return [deleteTransaction]
}

export default useDeleteTransaction
