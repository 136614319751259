import React from 'react'
import { FieldProps, useFormikContext } from 'formik'
import { Autocomplete, AutocompleteProps } from './Autocomplete'
import { LookupItem } from '../../types'

type LookupItemSelectFieldProps<T> = {
    list: T[]
    map: { [x: string]: T }
    update: (item: T) => void
    defaultValue?: string
    onChange?: AutocompleteProps<T>['onChange']
} & FieldProps

export const LookupItemSelectField = <T extends LookupItem>({
    list,
    map,
    update,
    field,
    onChange,
    ...props
}: LookupItemSelectFieldProps<T>) => {
    const { value, defaultValue } = { ...field, ...props }
    const defaultValueValid: T | undefined = defaultValue && map ? map[defaultValue] : undefined

    const { setFieldValue } = useFormikContext<any>()

    const handleChange = (fieldName, newValue) => {
        setFieldValue(fieldName, newValue)
    }

    return (
        <Autocomplete<T>
            {...field}
            {...props}
            onCreate={update}
            options={list}
            value={value && map && map[value]}
            defaultValue={defaultValueValid}
            onChange={onChange || handleChange}
        />
    )
}

export default LookupItemSelectField
