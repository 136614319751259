import React from 'react'
import { Layout } from 'antd'
import SimpleBar from 'simplebar-react'
import { Accounts } from './Accounts'
import { AccountsContainer } from '../containers/Accounts'
import { SidebarHeader } from './SidebarHeader'
import { SuspenseWithPerf } from 'reactfire'
import Loading from './Loading'

const { Sider } = Layout

export const Sidebar = () => (
    <Sider breakpoint="lg" collapsedWidth="0" width={350} theme="dark">
        <SimpleBar style={{ height: '100vh' }}>
            <SidebarHeader />
            <SuspenseWithPerf fallback={<Loading/>} traceId="sidebar">
                <AccountsContainer
                    // @ts-ignore
                    Layout={Accounts}
                />
            </SuspenseWithPerf>
        </SimpleBar>
    </Sider>
)

export default Sidebar
